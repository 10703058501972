.Badges__hero {
  width: 100%;
  padding: 2rem 0;
  background-repeat: repeat;
  margin-bottom: 1rem;
}

.Badges__hero > .Badges__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Badges__container {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  padding: 0 1rem;
}

.Badges_conf-logo {
  margin-bottom: 2rem;
}

.Badges__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.Badges__list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.Badgelist {
  background: #ffffff;
  box-shadow: 02px 2px 0 #08a0e9;
  /* separa la listas*/
  margin-bottom: 1rem;
  border-radius: 10px 10px 10px 10px;
  padding: 1rem 0;
  width: 100%;
}
.avatalist {
  border-radius: 50%;
  margin-right: 1rem;
  margin-left: 1rem;
}

.styletwitter {
  color: #08a0e9;
}
.logotwitter {
  width: 10%;
  height: 10%;
}

 .navbar {
  background-color: #04406D;
  color: white;
 }

 .nav-link {
    color: white;
  }
a {
  color: white;
}
footer {
  background-color: rgba(184, 181, 181, 0.466);
  width: 100%;
  height: 100%;
  color: rgb(3, 3, 3);
  padding:15px;
} 

footer .line {
  height: 1px;
  background-color: white
}
  
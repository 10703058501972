@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Anton);
 .navbar {
  background-color: #04406D;
  color: white;
 }

 .nav-link {
    color: white;
  }
a {
  color: white;
}
footer {
  background-color: rgba(184, 181, 181, 0.466);
  width: 100%;
  height: 100%;
  color: rgb(3, 3, 3);
  padding:15px;
} 

footer .line {
  height: 1px;
  background-color: white
}
  
@media only screen and (min-width: 640px) {
  .iconocontacto {
    width: 50%;
  }
}

.habilidades-transform{
  text-align: center;
  padding-top: 2%; 
  margin: 1%;
  color: white;
  width: 160px; 
  height: 50px;  
} 
.Badges__hero {
  width: 100%;
  padding: 2rem 0;
  background-repeat: repeat;
  margin-bottom: 1rem;
}

.Badges__hero > .Badges__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Badges__container {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  padding: 0 1rem;
}

.Badges_conf-logo {
  margin-bottom: 2rem;
}

.Badges__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.Badges__list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.Badgelist {
  background: #ffffff;
  box-shadow: 02px 2px 0 #08a0e9;
  /* separa la listas*/
  margin-bottom: 1rem;
  border-radius: 10px 10px 10px 10px;
  padding: 1rem 0;
  width: 100%;
}
.avatalist {
  border-radius: 50%;
  margin-right: 1rem;
  margin-left: 1rem;
}

.styletwitter {
  color: #08a0e9;
}
.logotwitter {
  width: 10%;
  height: 10%;
}

.Badge{
    background: white;
    /* redondea el borde*/
    border-radius: 8px 8px 8px 8px;
    height: 360px;
}

.BadgeHeader{
    background: #1B1B25;
    height: 80px;
    /*Centra el contenido*/
    display: flex;
    justify-content: center;
}
.BadgeName{
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 1rem 0;
}

.BadgeSectionInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    background: #F4F4F7;
  }
  .BadgeFooter {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #98CA3F;
    font-weight: bold;
    font-style: italic;
  }
.badgenewheader {
  width: 100%;
  padding: 2rem 0;
  background-repeat: repeat;
  margin-bottom: 1rem;
  color: #ffffff;
}

.banner {
  background-color:black;
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
}
.proyectolist {
  background-color: white;
  margin: 2%;
}

.logo-proyecto {
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .logo-proyecto  {
    width: 30%;
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  background-color: #F4F4F7;
}

h1 {
  font-family: 'Anton', sans-serif;
}

a.link-unstyled {
  color: inherit;
}

a.link-unstyled:hover {
  color: inherit;
  text-decoration: none;
}

.btn {
  padding: 0.375rem 2rem;
}

.btn.btn-primary {
  color: #fff;
  background-color: #7DCD40;
  border-color: #7DCD40;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #7DCD40;
  border-color: #7DCD40;
}

.btn.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #7DCD40;
  border-color: #7DCD40;
}

.btn.btn-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 205, 64, 0.5);
}

.btn.btn-danger {
  color: #fff;
  background-color: #CD4040;
  border-color: #CD4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #CD4040;
  border-color: #CD4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #CD4040;
  border-color: #CD4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 64, 64, 0.5);
}

@media only screen and (min-width: 640px) {
  .iconocontacto {
    width: 50%;
  }
}

.habilidades-transform{
  text-align: center;
  padding-top: 2%; 
  margin: 1%;
  color: white;
  width: 160px; 
  height: 50px;  
} 
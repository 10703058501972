.Badge{
    background: white;
    /* redondea el borde*/
    border-radius: 8px 8px 8px 8px;
    height: 360px;
}

.BadgeHeader{
    background: #1B1B25;
    height: 80px;
    /*Centra el contenido*/
    display: flex;
    justify-content: center;
}
.BadgeName{
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 1rem 0;
}

.BadgeSectionInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    background: #F4F4F7;
  }
  .BadgeFooter {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #98CA3F;
    font-weight: bold;
    font-style: italic;
  }